<template>
  <el-row class="group">
    <!-- 1.左侧分组管理 -->
    <el-col :span="4" class="main">
      <div class="header flex-spb">
        <div class="fw">号码分组管理</div>
        <el-button @click="dialogGroupVisible = true" type="primary" size="mini">添加分组</el-button>
      </div>
      <div class="flex">
         <el-select v-model="selectedUid" placeholder="请选择" size='small' clearable :style="{width: '110px',marginTop:'5px' }"> 
           <el-option
            v-for="item in groupList1"
            :key="item.amountId"
            :label="item.amountName"
            :value="item.amountId">
           </el-option>
  </el-select>
   <el-input v-model="grouping" clearable placeholder="请输入关键字" :style="{marginTop:'5px' }" size='small'>
          <template #append>
            <el-button icon="el-icon-search" @click="showList" />
          </template>
        </el-input>
      </div>
     
      <!-- 1.1主体 -->
      <div class="container">
         
         <el-collapse v-model="activeCollapse" accordion>
    <el-collapse-item v-for="(item, index1) in groupList1" :key="index1" :name="index1">
      <template #title>
        {{ item.amountName }}
      </template>
      <div v-for="(item1, index) in item.list" :key="index" @click="handleClick(item1, index)" class="row flex-spb flex-spb1" :class="[{ active: activeid === item1.id }, { highlight: item1.highlight }]">
        <div class="nameBox">{{ item1.name }}</div>
        <div class="btnBox">
          <el-button @click.stop="showGroupEdit(item1, item)" type="success" size="mini" circle icon="el-icon-edit"></el-button>
          <el-button @click.stop="handleGroupDel(item1.id)" type="danger" size="mini" circle icon="el-icon-delete"></el-button>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
      </div>
      
      <!-- <div class="container">
        <div @click="handleClick(item, index)" class="row  flex-spb" :class="activeIndex === index ? 'active' : ' '"
          v-for="(item, index) in groupList" :index="index">
          <div>{{ item.name }}</div>
          <div>
            <el-button @click.stop="showGroupEdit(item)" type="success" size="mini" circle icon="el-icon-edit">
            </el-button>
            <el-button @click.stop="handleGroupDel(item.id)" type="danger" size="mini" icon="el-icon-delete" circle>
            </el-button>
          </div>
        </div>
      </div> -->
      <!-- 1.2分页器 -->
      <!-- <pagi-nation :style="{marginTop:'20px'}" :total="groupTotal" :limit="groupPageSize" :page="groupPage"
        @handleChange="handleGroupChange"></pagi-nation> -->
    </el-col>
    <!-- 2.中间虚拟号码列表 -->
    <el-col :span="10" class="main">
      <div class="fw">虚拟号码列表  <div><el-cascader
                  filterable
                  clearable
                  :options="options"
                  placeholder="省份/城市"
                  :props="{ value: 'name', label: 'name', checkStrictly: true }"
                  v-model="searchArea"
                  @change="searchAreaChange"
                ></el-cascader>&nbsp;<el-button @click="exportTableAll" type="primary">导出数据</el-button> </div></div>
      <!-- 2.1头部搜索 -->
      <div class="search">
        <el-input v-model="virtualForm.number" clearable placeholder="请输入虚拟小号" :style="{ width: '210px', }">
          <template #append>
            <el-button icon="el-icon-search" @click="virtualPage = 1; getVirtualDataListcopy(virtualForm.numberGroupId)" />
          </template>
        </el-input>
        <el-select v-model="virtualForm.interfaceId" placeholder="请选择或输入接口账户" filterable clearable
          :style="{ width: '160px', marginLeft: '10px' }">
          <el-option v-for="(item, index) in accountList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-button @click="handleVirtualBatchDelAll(1)" :style="{ marginLeft: '10px' }" type="primary">添加虚拟号码
        </el-button>
        <el-button @click="handleVirtualBatchDel" type="primary">勾选删除</el-button>
        <el-button @click="handleVirtualBatchDelAll(2)" type="primary">批量删除</el-button>
      </div>

      <!-- 2.2主体表格 -->
      <el-table :style="{ marginTop: '10px' }" :data="virtualTableData" tooltip-effect="dark" max-height="450"
        :page-sizes="[10, 20, 50, 100]" :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
        @selection-change="handleVirtualSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="虚拟小号" width="180" :show-overflow-tooltip="true">
          <template #default="scope">{{
            scope.row.number == null ? "-" : scope.row.number
          }}</template>
        </el-table-column>
        <el-table-column label="行业名称" :show-overflow-tooltip="true">
          <template #default="scope">{{
            scope.row.industryName == null ? "-" : scope.row.industryName
          }}</template>
        </el-table-column>
         <el-table-column label="省份/城市" :show-overflow-tooltip="true">
          <template #default="scope">{{
            scope.row.city == null ? "-" : scope.row.province
          }}/{{
            scope.row.city == null ? "-" : scope.row.city
          }}</template>
        </el-table-column>
        <el-table-column label="服务商">
          <template #default="scope">{{
            scope.row.amountName == null ? "-" : scope.row.amountName
          }}</template>
        </el-table-column>
        <el-table-column label="接口账户">
          <template #default="scope">{{
            scope.row.interfaceName == null ? "-" : scope.row.interfaceName
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button @click.stop="handleVirtualDel(scope.row.id)" type="danger" size="mini" icon="el-icon-delete"
              circle>
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <!-- 2.3分页器 -->
      <pagi-nation :pageSizesType="[10, 20, 50, 100]" :style="{ marginTop: '20px', float: 'right' }" :total="virtualTotal"
        :limit="virtualPageSize" :page="virtualPage" @handleChange="handleVirtualChange"></pagi-nation>
    </el-col>
    <!-- 3.右侧真实号码列表 -->
    <el-col :span="10" class="main">
      <div class="fw">真实号码列表
        <div><el-cascader
                  filterable
                  clearable
                  :options="options"
                   placeholder="省份/城市"
                  :props="{ value: 'name', label: 'name', checkStrictly: true }"
                  v-model="searchArea1"
                  @change="searchAreaChange1"
                ></el-cascader>&nbsp;
                 <el-button @click="exportTableAll1" type="primary">导出数据</el-button></div>
        
      </div>
      <!-- 3.1头部搜索 -->
      <div class="search">
        <el-input v-model="realForm.number" placeholder="请输入真实小号" clearable :style="{ width: '210px' }">
          <template #append>
            <el-button icon="el-icon-search" @click="realPage = 1; getRealData(realForm.numberGroupId)" />
          </template>
        </el-input>
        <el-select v-model="realForm.interfaceId" placeholder="请选择或输入接口账户" filterable clearable
          :style="{ width: '160px', marginLeft: '10px' }">
          <el-option v-for="(item, index) in accountList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-button @click="handleRealBatchDelAll(1)" :style="{ marginLeft: '10px' }" type="primary">添加真实号码</el-button>
        <el-button @click="handleRealBatchDel" type="primary">勾选删除</el-button>
         <el-button @click="handleRealBatchDelAll(2)" type="primary">批量删除</el-button>
      </div>
      <!-- 3.2主体表格 -->
      <el-table :style="{ marginTop: '10px' }" :data="realTableData" tooltip-effect="dark" max-height="450"
        :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }" @selection-change="handleRealSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="真实小号" width="200" :show-overflow-tooltip="true">
          <template #default="scope">{{
            scope.row.number == null ? "-" : scope.row.number
          }}</template>
        </el-table-column>
        <el-table-column label="行业名称" :show-overflow-tooltip="true">
          <template #default="scope">{{
            scope.row.industryName == null ? "-" : scope.row.industryName
          }}</template>
        </el-table-column>
           <el-table-column label="省份/城市" :show-overflow-tooltip="true">
          <template #default="scope">{{
            scope.row.province == null ? "-" : scope.row.province
          }}/{{
            scope.row.city == null ? "-" : scope.row.city
          }}</template>
        </el-table-column>
        <el-table-column label="服务商">
          <template #default="scope">{{
            scope.row.amountName == null ? "-" : scope.row.amountName
          }}</template>
        </el-table-column>
        <el-table-column label="接口账户">
          <template #default="scope">{{
            scope.row.interfaceName == null ? "-" : scope.row.interfaceName
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button @click="handleRealDel(scope.row.id)" type="danger" icon="el-icon-delete" size="mini" circle>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 2.3分页器 -->
      <pagi-nation :pageSizesType="[10, 20, 50, 100]" :style="{ marginTop: '20px', float: 'right' }" :total="realTotal"
        :limit="realPageSize" :page="realPage" @handleChange="handleRealChange"></pagi-nation>
    </el-col>
    <!-- 添加分组模态框 -->
    <el-dialog title="添加号码分组" v-model="dialogGroupVisible" :show-close="false" :close-on-click-modal="false"
      :destroy-on-close="true" width="30%">
      <el-form label-position="left" label-width="100px" size="small" :model="searchForm" ref="searchForm" :rules="rules">
        <div class="form-out-box p-0-30">
          <el-form-item label="服务商" prop="amountId">
            <el-select v-model="searchForm.amountId" placeholder="请选择服务商" style="width: 100%" clearable filterable
              @change="changeValues">
              <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="GroupName">
        <span>分组名称</span><el-input  v-model="groupName" placeholder="请输入分组名称"></el-input>
      </div>
      
         
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogGroupVisible = false; groupName = ''">取消</el-button>
          <el-button type="primary" @click="handleGroupAdd">确认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑分组模态框 -->
    <el-dialog title="编辑号码分组" v-model="dialogGroupEditVisible" :show-close="false" :close-on-click-modal="false"
      :destroy-on-close="true" width="30%">
      <el-form label-position="left" label-width="100px" size="small" :model="searchForm" ref="searchForm" :rules="rules">
        <div class="form-out-box p-0-30">
          <el-form-item label="服务商" prop="amountId">
            <el-select v-model="searchForm.amountId" placeholder="请选择服务商" style="width: 100%" clearable filterable
              @change="changeValues1">
              <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="GroupName">
        <span>分组名称</span> <el-input v-model="groupEditForm.groupEditName"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogGroupEditVisible = false">取消</el-button>
          <el-button type="primary" @click="handleGroupEdit">确认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量添加虚拟号码模态框 -->
    <el-dialog :title="virtualNumber==1?'添加虚拟号码':'批量删除'" v-model="dialogVirtualVisible" :show-close="false" :close-on-click-modal="false"
      :destroy-on-close="true" width="30%">
      <el-input v-model="virtualName" :rows="8" type="textarea" placeholder="请输入虚拟号码,多个号码请换行输入"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVirtualVisible = false; virtualName = ''">取消</el-button>
          <el-button type="primary" @click="handleVirtualAdd">确认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量添加真实号码模态框 -->
    <el-dialog :title="realNumber==1?'添加真实号码':'批量删除'" v-model="dialogRealVisible" :show-close="false" :close-on-click-modal="false"
      :destroy-on-close="true" width="30%">
      <el-input v-model="realName" :rows="8" type="textarea" placeholder="请输入真实号码,多个号码请换行输入"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogRealVisible = false; realName = ''">取消</el-button>
          <el-button type="primary" @click="handleRealAdd">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </el-row>
</template>

<script>
import {
  numberGroupQuery,
  numberGroupList,
  numberGroupAdd,
  numberGroupEdit,
  numberGroupDel,
  virtualNumberList,
  virtualNumberBatchAdd,
  virtualNumberDel,
  virtualNumberBatchDel,
  realNumberList,
  realNumberBatchAdd,
  realNumberDel,
  realNumberBatchDel,
  serviceProviderGroup,
  virtualExport,
  realityExport,
  realityRatchDeletePlus,
  virtualRatchDeletePlus
} from '@/api/open/numberGroup/index'
import area from "@/api/open/area/area";
import amountts from '@/api/open/privacy/amount'
import interfacets from '@/api/open/privacy/interface'
import Pagination from '../../components/Pagination.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import amount from '@/api/open/amount/amount';
export default {
  components: {
    'pagi-nation': Pagination,
  },
  data() {
    return {
      selectedUid:null,
      grouping:'', //分组搜索
      activeCollapse: [], // 用于控制 el-collapse 激活的面板
      options: [],
      searchArea: [],
      searchArea1: [],
      GroupListId: '',
      activeIndex: 0,
      dialogGroupVisible: false, //号码分组--->添加
      dialogGroupEditVisible: false, //号码分组--->编辑
      dialogVirtualVisible: false, //虚拟号码--->添加
      dialogRealVisible: false, //真实号码--->添加
      accountList: [], // 拿到的通信账户类型
      realTotal: 0,
      groupName: '', //分组名称--->添加
      groupEditName: '', //分组名称--->编辑
      groupList: [], //分组列表数据
      groupList1: [],
      //号码分组表单--->列表
      // groupTotal: 0,
      // groupPage: 1,
      // groupPageSize: 10,
      groupForm: {
        name: '',
      },
      interfaceId: '',
      numberGroupId: '',
      //获取服务商下的分组信息
      serviceGroup: {
        amountId: '',
        id: '',
      },
      searchForm: {
        amountId: '',
      },
      searchForm1: {
        amountId: '',
      },
      amount_id: [], // 拿到的通信账户类型
      //号码分组表单--->编辑
      groupEditForm: {
        id: '',
        groupEditName: '',
      },
      virtualName: '', //虚拟号码--->添加
      //虚拟号码表单--->列表
      virtualMultipleSelection: [], //多选删除
      virtualTableData: [],
      virtualTotal: 0,
      virtualPage: 1,
      virtualPageSize: 10,
      virtualForm: {
        number: '', //号码
        amountId: '', //服务商id
        interfaceId: '', //接口id
        numberGroupId: '', //分组id
        province:'',
        city:''
      },
      realName: '', //真实号码--->添加
      //真实号码表单--->列表
      realMultipleSelection: [], //多选删除
      realTableData: [],
      realTotal: 0,
      realPage: 1,
      realPageSize: 10,
      realForm: {
        number: '', //号码
        interfaceId: '', //接口id
        amountId: '', //服务商id
        numberGroupId: '', //分组id
        province:'',
        city:''
      },
      realNumber:'',
      virtualNumber:'',
      rules: {
        amountId: [
          {
            required: true,
            message: '请选择服务商',
            trigger: 'blur',
          },
        ],
      },
      activeid:'',
      result: null,
    }
  },
  mounted() {
    this.getAmountList()
    this.getGroupList()
    // this.getServiceGroup()
    this.getAmountType()
    this.getGroupListCopy()
    this.getAreaList()
  },
  watch: {
    groupList1: {
      handler(newVal, oldVal) {
       // const hasActiveId = newVal.some(item => item.list[0].id == this.activeid);
         let arr=[]
        newVal.forEach(element => {
          Array.prototype.push.apply(arr, element.list);
       //  arr= arr.concat(element.list);
        });
      const hasActiveId = arr.some(item => item.id == this.activeid);
        if (hasActiveId) {
        } else {
          this.activeid=''
        }
      },
      deep: true
    },


    'virtualForm.interfaceId'() {
      this.virtualPage = 1
      //  this.getVirtualDataList(this.virtualForm.numberGroupId)
    },
    'realForm.interfaceId'() {
      this.realPage = 1
      //this.getRealDataList(this.realForm.numberGroupId)
    },
  },
  methods: {
     showList() {
            this.result = null;
            if (!this.selectedUid) {
                return this.$message.error('请先选择分组再进行查询');
            }
            if (this.grouping) {
                const selectedItem = this.groupList1.find(item => item.amountId === this.selectedUid);
                if (selectedItem) {
                    let found = false; // 增加一个标志变量来记录是否找到匹配项
                    selectedItem.list.forEach(subItem => {
                        if (subItem.name.includes(this.grouping)) {
                            subItem.highlight = true;
                            if (!found) {
                                this.result = subItem.id;
                                this.activeCollapse = [this.groupList1.indexOf(selectedItem)];
                                this.activeid = subItem.id;
                                found = true; // 找到匹配项后设置标志变量为 true
                            }
                        } else {
                            subItem.highlight = false; // 重置不匹配的项
                        }
                    });
                }
                console.log(this.activeid);
            } else {
                this.activeCollapse = [];
                this.activeid = null;
                // 重置所有项的高亮状态
                this.groupList1.forEach(item => {
                    item.list.forEach(subItem => {
                        subItem.highlight = false;
                    });
                });
            }

            if (this.result == null) {
                return this.$message.error('无筛选结果');
            }

            this.getRealDataListcopy1(this.result);
            this.getVirtualDataList(this.result);
        },
    setActiveCollapse() {
      if (this.GroupListId) {
        this.groupList1.forEach((group, index1) => {
          group.list.forEach(item => {
            if (item.id === this.GroupListId) {
              this.activeCollapse = [index1];
              this.activeid = item.id;
            }
          });
        });
      } else {
        this.activeCollapse = [];
        this.activeid = null;
      }
    },
    handleVirtualBatchDelAll(e){
      this.virtualNumber=e
      this.dialogVirtualVisible = true 
    },
    handleRealBatchDelAll(e){
      this.realNumber=e
      this.dialogRealVisible = true
    },
    exportTableAll() {
      ElMessageBox.confirm('将导出全部数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
     
          if (this.virtualTableData.length==0) {
            return  ElMessage({
            type: 'info',
            message: '列表暂无导出数据',
          })
          }
          virtualExport({ 
             ...this.virtualForm
              })
            .then((res) => {
              if (res.code !== 200) {
                return ElMessage({
                  type: 'error',
                  message: res.message,
                })
              }
              ElMessage({
                type: 'success',
                message: '列表导出导出任务已建立,稍后请在消息模块下载',
              })
            })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消',
          })
        })
    },
    exportTableAll1() {
      ElMessageBox.confirm('将导出全部数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
     
          if (this.realTableData.length==0) {
            return  ElMessage({
            type: 'info',
            message: '列表暂无导出数据',
          })
          }
          realityExport({ 
             ...this.realForm
              })
            .then((res) => {
              if (res.code !== 200) {
                return ElMessage({
                  type: 'error',
                  message: res.message,
                })
              }
              ElMessage({
                type: 'success',
                message: '列表导出导出任务已建立,稍后请在消息模块下载',
              })
            })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消',
          })
        })
    },
     getAreaList() {
      area.dropDownTreeList().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    searchAreaChange(event){
     if (event) {
       this.virtualForm.province = event[0].replace(/省|市$/, '')
       this.virtualForm.city = event[1] ? event[1].replace(/市$/, '') : '';
      }else{
          this.virtualForm.province = ''
           this.virtualForm.city =''
      }
       this.getVirtualDataListcopy()
    },
    searchAreaChange1(event){
     if (event) {
       this.realForm.province = event[0].replace(/省|市$/, '')
       this.realForm.city = event[1] ? event[1].replace(/市$/, '') : '';
      }else{
         this.realForm.province =''
       this.realForm.city = '';
      }
      this.getVirtualDataListcopy()
    },
 // 虚拟号码--->列表
 getVirtualDataListcopy(id) {
  // if (id) {
  //    this.virtualForm.numberGroupId = id
  // }else{
  //    this.virtualForm.numberGroupId = ''
  // }
      //getVirtualDataList
      //this.getVirtualDataList()getGroupList
     this.virtualForm.numberGroupId = ''
      virtualNumberList({
        ...this.virtualForm,
        page: this.virtualPage,
        pageSize: this.virtualPageSize,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          if (res.data.records.length > 0) {
            this.GroupListId = res.data.records[0].numberGroupId

          }
          else {
            // if (this.virtualForm.number=='') {
            //   this.getGroupListCopy()
            // }
            this.GroupListId = ''
          }
          this.virtualTableData = res.data.records
          this.virtualTotal = res.data.total
          this.getGroupListCopy('xuni')
          this.getRealDataListcopy()
          this.setActiveCollapse();
        }
      })
    },

    getRealData() {

      // 真实号码--->列表
      this.realForm.numberGroupId = ''
      realNumberList({
        ...this.realForm,
        page: this.realPage,
        pageSize: this.realPageSize,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          if (res.data.records.length > 0) {
            this.GroupListId = res.data.records[0].numberGroupId
          }
          else {
            this.GroupListId = ''
          }
          this.realTableData = res.data.records
          this.realTotal = res.data.total
          this.getGroupListCopy('zhengshi')
          this.getVirtualDataList(this.GroupListId)
          this.setActiveCollapse();
        }
      })



    },


    // 选中服务商
    changeValues() {
      console.log(this.searchForm.amountId);
    },
    // 选中服务商
    changeValues1() {
      console.log(this.groupEditForm.amountId);
    },
    // 虚拟号码--多选
    handleVirtualSelectionChange(row) {
      this.virtualMultipleSelection = row.map((item) => {
        return item.id
      })
    },
    // 真实号码--多选
    handleRealSelectionChange(row) {
      this.realMultipleSelection = row.map((item) => {
        return item.id
      })
    },
    //接口账户下拉
    getAmountList() {
      interfacets.getInterface({
        // interfaceId:this.interfaceId,
        // numberGroupId:this.numberGroupId,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.accountList = res.data
        }
      })
    },
    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
    },
    // getVirtualDataListcopy(id) {
    //   this.getVirtualDataListcopy('')
     
    // },

    // 真实号码--->列表
    getRealDataListcopy(id) {
      // if (id) {
      //   this.realForm.numberGroupId =id
      // }else{
      //    this.realForm.numberGroupId = ''
      // }
    this.realForm.numberGroupId = id
      realNumberList({
        ...this.realForm,
        page: this.realPage,
        pageSize: this.realPageSize,
        numberGroupId: this.GroupListId
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.realTableData = res.data.records
          this.realTotal = res.data.total
           this.realForm.numberGroupId = ''
           this.GroupListId=''
          // this.grouping=''
        }
      })
    },

    // 真实号码--->列表
    getRealDataListcopy1(id) {
      // if (id) {
      //   this.realForm.numberGroupId =id
      // }else{
      //    this.realForm.numberGroupId = ''
      // }
    this.realForm.numberGroupId = id
      realNumberList({
        ...this.realForm,
        page: this.realPage,
        pageSize: this.realPageSize,
        numberGroupId: id
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.realTableData = res.data.records
          this.realTotal = res.data.total
           this.realForm.numberGroupId = ''
           this.GroupListId=''
        }
      })
    },

    // 虚拟号码--->列表
    getVirtualDataList(id) {
      //getVirtualDataList
      //this.getVirtualDataList()getGroupList
      this.virtualForm.numberGroupId = id
      virtualNumberList({
        ...this.virtualForm,
        page: this.virtualPage,
        pageSize: this.virtualPageSize,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.virtualTableData = res.data.records
          this.virtualTotal = res.data.total
          // this.grouping=''
        }
      })
    },
    //虚拟号码--->添加
    handleVirtualAdd() {
      // const Reg = /^1[3-9]\d{9}$/
      if (this.virtualName == '') {
        ElMessage({
          message: '手机号码输入不能为空!',
          type: 'warning',
        })
      } else {

          if(!this.activeid){
            return   ElMessage({
              message: '请选择分组!',
                 type: 'warning',
                })
           }
        let numbers = this.virtualName.split(/\r\n|\r|\n/)
         let url=this.virtualNumber==1?virtualNumberBatchAdd:virtualRatchDeletePlus
        url({
          numberGroupId: this.activeid,
          numbers: numbers,
        }).then((res) => {
          if (res.code !== 200) {
            ElMessage({
              message: res.message,
              type: 'error',
            })
          } else {
            if (res.data?.error) {
              ElMessage({
                message: `${res.data.error}号码重复!`,
                type: 'warning',
              })
            } else {
              ElMessage({
                message:this.virtualNumber==1 ?'添加成功!':'删除成功!',
                type: 'success',
              })
            }
            this.virtualName = ''
            this.dialogVirtualVisible = false
            this.getVirtualDataList(this.virtualForm.numberGroupId)
          }
        })
      }
    },
    // 虚拟号码--->删除
    handleVirtualDel(id) {
      ElMessageBox.confirm('确认删除该虚拟小号吗?', '温馨提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          virtualNumberDel({ id: id }).then((res) => {
            if (res.code !== 200) {
              ElMessage({
                message: res.message,
                type: 'error',
              })
            } else {
              ElMessage({
                message: '删除成功!',
                type: 'success',
              })
              this.getVirtualDataList(this.virtualForm.numberGroupId)
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除!',
          })
        })
    },
    // 虚拟号码--勾选删除
    handleVirtualBatchDel() {
      if (this.virtualMultipleSelection.length == 0) {
        ElMessage({
          message: '请选择要删除的号码!',
          type: 'warning',
        })
      } else {
        ElMessageBox.confirm('确认删除该虚拟小号吗?', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let ids = this.virtualMultipleSelection.map((item) => {
              return item
            })
            virtualNumberBatchDel({ ids: ids }).then((res) => {
              if (res.code !== 200) {
                ElMessage({
                  message: res.message,
                  type: 'error',
                })
              } else {
                ElMessage({
                  message: '删除成功!',
                  type: 'success',
                })
                this.virtualMultipleSelection = []
                this.getVirtualDataList(this.virtualForm.numberGroupId)
              }
            })
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消删除!',
            })
          })
      }
    },
    // 虚拟号码--->分页
    handleVirtualChange(params) {
      this.virtualPageSize = params.limit
      this.virtualPage = params.page
      this.getVirtualDataList(this.virtualForm.numberGroupId)
    },
    // 真实号码--->列表
    getRealDataList(id) {
      this.realForm.numberGroupId = id
        console.log(this.realForm.numberGroupId,'pppppppppppppppppppp');
      realNumberList({
        ...this.realForm,
        page: this.realPage,
        pageSize: this.realPageSize,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.realTableData = res.data.records
          this.realTotal = res.data.total
        }
      })
    },
    //真实号码--->添加 真实号码--->删除
    handleRealAdd() {
      // const Reg = /^1[3-9]\d{9}$/
      if (this.realName == '') {
        ElMessage({
          message: '手机号码输入不能为空!',
          type: 'warning',
        })
      } else {
        if(!this.activeid){
            return   ElMessage({
              message: '请选择分组!',
                 type: 'warning',
                })
           }
        let numbers = this.realName.split(/\r\n|\r|\n/)
        let url=this.realNumber==1?realNumberBatchAdd:realityRatchDeletePlus
        url({
          numberGroupId: this.activeid,
          numbers: numbers,
        }).then((res) => {
          if (res.code !== 200) {
            ElMessage({
              message: res.message,
              type: 'error',
            })
          } else {
            if (res.data?.error) {
              ElMessage({
                message: `${res.data.error}号码重复!`,
                type: 'warning',
              })
            } else {
              ElMessage({
                message:this.realNumber==1 ?'添加成功!':'删除成功!',
                type: 'success',
              })
            }
            this.realName = ''
            this.dialogRealVisible = false
            this.getRealDataList(this.activeid)
          }
        })
      }
    },
    // 真实号码--->删除
    handleRealDel(id) {
      ElMessageBox.confirm('确认删除该真实小号吗?', '温馨提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          realNumberDel({ id: id }).then((res) => {
            if (res.code !== 200) {
              ElMessage({
                message: res.message,
                type: 'error',
              })
            } else {
              ElMessage({
                message: '删除成功!',
                type: 'success',
              })
              this.getRealDataList(this.realForm.numberGroupId)
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除!',
          })
        })
    },
    // 真实号码--勾选删除
    handleRealBatchDel() {
      if (this.realMultipleSelection.length == 0) {
        ElMessage({
          message: '请选择要删除的号码!',
          type: 'warning',
        })
      } else {
        ElMessageBox.confirm('确认删除该真实小号吗?', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let ids = this.realMultipleSelection.map((item) => {
              return item
            })
            realNumberBatchDel({ ids: ids }).then((res) => {
              if (res.code !== 200) {
                ElMessage({
                  message: res.message,
                  type: 'error',
                })
              } else {
                ElMessage({
                  message: '删除成功!',
                  type: 'success',
                })
                this.realMultipleSelection = []
                this.getRealDataList(this.realForm.numberGroupId)
              }
            })
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消删除!',
            })
          })
      }
    },
    // 真实号码--->分页
    handleRealChange(params) {
      this.realPageSize = params.limit
      this.realPage = params.page
      this.getRealDataList(this.realForm.numberGroupId)
    },
    // 号码分组--->分页
    // handleGroupChange(params) {
    //   this.groupPageSize = params.limit
    //   this.groupPage = params.page
    //   this.getGroupList()
    // },
    //号码分组--->列表
    getGroupList() {
      numberGroupQuery({
        ...this.groupForm,
        // page: this.groupPage,
        // pageSize: this.groupPageSize,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.groupList = res.data
          
          // this.groupTotal = res.data.total
          this.activeIndex = 0
          this.getVirtualDataList(this.groupList[0].id)
          this.getRealDataList(this.groupList[0].id)
        }
      })
    },
    //号码分组--->列表
    getGroupListCopy(e) {
if(e){
  if(e=='xuni'){
   if(!this.virtualForm.number){ 
    this.GroupListId=''
   }
  }
  else{
    if(!this.realForm.number){
    this.GroupListId=''
   }
  }
}
      serviceProviderGroup({
        // ...this.groupForm,
          id:'',
        //id:this.virtualForm.numberGroupId,
        // ...this.virtualForm,
        // page: this.groupPage,
        // pageSize: this.groupPageSize,
      }).then((res) => {
        if (res.code !== 200) {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        } else {
          this.groupList1 = res.data
        }
      })
    },
    
    //号码分组--->添加
    handleGroupAdd() {
      if (this.groupName == '') {
        ElMessage({
          message: '分组名称输入不能为空!',
          type: 'warning',
        })
      } else {
        numberGroupAdd({
          name: this.groupName,
          amountId: this.searchForm.amountId
        }).then((res) => {
          if (res.code !== 200) {
            ElMessage({
              message: res.message,
              type: 'error',
            })
          } else {
            this.dialogGroupVisible = false
            
            this.groupName = ''
            ElMessage({
              message: '添加成功!',
              type: 'success',
            })
            this.getGroupListCopy()
          }
        })
      }
    },

    //号码分组--->删除
    handleGroupDel(id) {
      ElMessageBox.confirm('确认删除该分组吗?', '温馨提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          numberGroupDel({ id: id }).then((res) => {
            if (res.code !== 200) {
              ElMessage({
                message: res.message,
                type: 'error',
              })
            } else {
              ElMessage({
                message: '删除成功!',
                type: 'success',
              })
              this.getGroupListCopy()
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除!',
          })
        })
    },

    //号码分组--->编辑
    showGroupEdit(item1,item) {
      this.groupEditForm.id = item1.id
      this.groupEditForm.groupEditName = item1.name
      this.searchForm.amountId=item.amountId.toString();
      this.dialogGroupEditVisible = true
    },
    
     //编辑确认
    handleGroupEdit() {
      if (this.groupEditForm.groupEditName == '') {
        ElMessage({
          message: '分组名称输入不能为空!',
          type: 'warning',
        })
      } else {
        console.log(this.searchForm.amountId);
        numberGroupEdit({
          id: this.groupEditForm.id,
          name: this.groupEditForm.groupEditName,
          amountId:this.searchForm.amountId
        }).then((res) => {
          
          if (res.code !== 200) {
            ElMessage({
              message: res.message,
              type: 'error',
            })
          } else {
            this.groupEditForm.id = ''
            this.groupEditForm.groupEditName = ''
            this.searchForm.amountId=''
            this.dialogGroupEditVisible = false
            this.getGroupListCopy()
            ElMessage({
              message: '修改成功!',
              type: 'success',
            })
          }
        })
      }
    },
    //点击分组请求列表数据
    handleClick(item1, index) {
      this.groupList1.forEach(group => {
        group.list.forEach(item1 => {
            item1.highlight = false;
        });
    });
      this.activeid=item1.id
      this.activeIndex = index
       this.virtualForm.number=''
      this.realForm.number=''
      this.grouping=''
      this.getVirtualDataList(item1.id)
      this.getRealDataList(item1.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.group {
  height: 100%;
  .active {
    background-color: #adbbff;
  }
   .highlight {
    background-color: #adbbff;
  }
  .fw {
    display: flex;
    justify-content: space-between;
    font-weight: 550;
    font-size: 16px;
  }

  .flex-spb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
.flex-spb1{
  cursor: pointer;
  margin-top: 10px;
  height: 50px;
}
// .colItem{
//   width: 100%;
// }
  .main {
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .container {
      width: 100%;
      height: 70vh;
      overflow: auto;
      margin-top: 10px;

      .row {
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
      }
    }

    .search {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 20px;
    }
  }
}
.GroupName span{
  width: 20%;
}
.GroupName{
  display: flex;
  justify-content: flex-start;
align-items: center;
}
.nameBox{
  width: 60%;
   
}
.btnBox{
  width: 40%;
  
}
</style>